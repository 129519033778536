export const menuItems = [
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.devices.text',
        icon: 'ri-layout-masonry-fill',
        subItems: [
            {
                id: 300,
                label: 'menuitems.devices.lists',
                link: '/device/list'
            },
            {
                id: 301,
                label: 'menuitems.devices.orders',
                link: '/device/orders'
            },
            {
                id: 302,
                label: 'menuitems.devices.console',
                link: '/device/console'
            }
        ]
    },
    {
        id: 4,
        label: "menuitems.sales.text",
        icon: 'ri-list-ordered',
        subItems: [
            {
                id: 400,
                label: 'menuitems.sales.orders',
                link: '/sales/orders'
            },
            {
                id: 401,
                label: 'menuitems.sales.paysetting',
                link: '/sales/paysetting'
            },
            {
                id: 402,
                label: 'menuitems.sales.sharingmrg',
                link: '/sales/sharingmrg'
            },
            
        ]
    },
    {
        id: 5,
        label: 'menuitems.iot.text',
        icon: 'ri-server-line',
        subItems: [
            {
                id: 500,
                label: 'menuitems.iot.gateway',
                link: '/iot/gateway'
            },
            {
                id: 501,
                label: 'menuitems.iot.printer',
                link: '/iot/printer'
            }
        ]
    },
    {
        id: 6,
        label: 'menuitems.app.text',
        icon: 'ri-apps-line',
        subItems: [
            {
                id: 600,
                label: 'menuitems.app.my',
                link: '/app/my'
            },
            {
                id: 601,
                label: 'menuitems.app.shop',
                link: '/app/shop'
            },
            {
                id: 602,
                label: 'menuitems.app.bill',
                link: '/app/bill'
            }
        ]
    },
    {
        id: 7,
        label: 'menuitems.wechat.text',
        icon: 'ri-wechat-fill',
        subItems: [
            {
                id: 700,
                label: 'menuitems.wechat.wechatmp.text',
                link: '/wechat/mp'
            },
            {
                id: 701,
                label: 'menuitems.wechat.micoapp.text',
                link: '/wechat/micoapp'
            }
        ]
    },
    {
        id: 8,
        label: 'menuitems.setting.text',
        icon: 'ri-settings-line',
        subItems: [
            {
                id: 800,
                label: 'menuitems.setting.account',
                link: '/setting/account'
            },
            {
                id: 801,
                label: 'menuitems.setting.safty',
                link: '/setting/safty'
            }
        ]
    },
    {
        id: 18,
        isLayout: true
    }
]