<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";

export default {
  components: { Vertical },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed
  },
  mounted(){
      var that=this
      var userinfo = JSON.parse(localStorage.getItem('user'));
      that.$axios.post('https://admin.aicoiot.beer/api.json', {
          action: 'jurisdiction'
      },{
          headers: { "Openid": userinfo.openid }
      }).then(function (response) {
          sessionStorage.setItem("jurisdiction", JSON.stringify(response.data.data.map));
      }).catch(function (error) {
          console.log(error);
      });
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if(flag){
           that.$root.ismobile= true
      }
      that.$axios.post('https://admin.aicoiot.beer/api.json', {
          action: 'loadapp'
      },{
          headers: { "Openid": userinfo.openid }
      }).then(function (response) {
          var res=response.data
          var activeapp = []
          for(var i=0;i<res.data.length;i++){
              activeapp.push(res.data[i].info.app);
          }
          sessionStorage.setItem("apps", JSON.stringify(activeapp));
      }).catch(function (error) {
          console.log(error);
      });
  },
  methods: {}
};
</script>

<template>
  <div>
    <vertical :layout="layoutType">
      <slot />
    </vertical>
  </div>
</template>
